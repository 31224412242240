(function () {
  'use strict';

  /* @ngdoc object
   * @name entitats.entitat
   * @description
   *
   */
  angular
  .module('neo.home.entitats.entitat', [
    'ui.router',
    'neo.home.entitats.entitat.informacio',
      'neo.home.entitats.entitat.printCards'
  ]);
}());
